import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {APP_DEFINITION_ID, PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {StoreInfoService} from './StoreInfoService';
import {ICanCheckoutResponse, IProductDTO, IPropsInjectedByViewerScript} from '../types/app-types';
import {ModalManager} from '@wix/wixstores-client-core/dist/es/src/modalManager/modalManager';
import {baseModalUrl, ModalState, ModalTheme, ModalType, Origin} from '../constants';
import {SPECS} from '../specs';
import {IStoreInfo} from '@wix/wixstores-graphql-schema';
import {LostBusinessNotifier} from './LostBusinessNotifier';
import _ from 'lodash';
import {isWorker} from '@wix/wixstores-client-core/dist/es/src/viewer-script/utils';

export const CHECKOUT_URL = '/_api/wix-ecommerce-renderer-web/store-front/checkout/cart/{cartId}';

export interface CheckoutInfo {
  cartId: string;
  isFastFlow: boolean;
  isPickupFlow: boolean;
  checkoutRelativeUrl: string;
  siteBaseUrl: string;
  thankYouPageUrl: string;
  cartUrl: string;
  paymentMethodName: string;
  locale: string;
  deviceType: string;
  a11y: boolean;
}

export class CheckoutService {
  private readonly siteStore: SiteStore;
  private readonly httpClient: Http;
  private readonly storeInfoService: StoreInfoService;
  private readonly modalManger: ModalManager;
  private readonly lostBusinessNotifier: LostBusinessNotifier;

  constructor(
    siteStore: SiteStore,
    httpClient: Http,
    private readonly nextProps: (additionalProps: Partial<IPropsInjectedByViewerScript>) => void
  ) {
    this.siteStore = siteStore;
    this.httpClient = httpClient;
    this.storeInfoService = new StoreInfoService(this.siteStore);
    const openModal = async (url, width, height, bareTheme) => {
      const theme = bareTheme ? ModalTheme.BARE : ModalTheme.DEFAULT;
      return this.siteStore.windowApis.openModal(url, {width, height, theme});
    };
    this.modalManger = new ModalManager({openModal}, baseModalUrl, this.siteStore.instance);
    this.lostBusinessNotifier = new LostBusinessNotifier(this.siteStore.httpClient);
  }

  private readonly navigateToCheckoutOutViewer = async (checkoutInfo: CheckoutInfo) => {
    const checkoutUrl = await this.getCheckoutOutOfViewerUrl(
      checkoutInfo.deviceType,
      checkoutInfo.a11y,
      {
        paymentMethodName: checkoutInfo.paymentMethodName,
        locale: checkoutInfo.locale,
        cartId: checkoutInfo.cartId,
        successUrl: checkoutInfo.thankYouPageUrl,
        cancelUrl: checkoutInfo.cartUrl,
        siteBaseUrl: checkoutInfo.siteBaseUrl,
        isFastFlow: checkoutInfo.isFastFlow,
        isPickupFlow: checkoutInfo.isPickupFlow,
      },
      {cartId: checkoutInfo.cartId}
    );

    if (isWorker()) {
      this.siteStore.location.to(checkoutUrl);
    } else {
      window.open(checkoutUrl, '_top');
    }
  };

  private readonly getCheckoutOutOfViewerUrl = async (
    deviceType: string,
    a11y: boolean,
    queryParams: {
      paymentMethodName: string;
      locale: string;
      cartId: string;
      successUrl: string;
      cancelUrl: string;
      siteBaseUrl: string;
      isFastFlow: boolean;
      isPickupFlow: boolean;
    },
    templateParams: {cartId: string}
  ): Promise<string> => {
    const serverResult = (await this.httpClient.get(CHECKOUT_URL, {templateParams, queryParams})) as any;
    return `${serverResult.url}&deviceType=${deviceType}&a11y=${a11y}`;
  };

  private readonly isEligibleForCheckoutInViewer = async (): Promise<boolean> => {
    const isCheckoutInstalled = await this.siteStore.siteApis.isAppSectionInstalled({
      appDefinitionId: APP_DEFINITION_ID,
      sectionId: PageMap.CHECKOUT,
    });

    const url = this.siteStore.location.url;
    const isSslSecured = _.startsWith(url, 'https');
    return isCheckoutInstalled && isSslSecured;
  };

  private readonly sendLostBusinessEmail = (storeInfo: IStoreInfo) => {
    storeInfo.isPremium && this.lostBusinessNotifier.notify();
  };

  public openModalByType = async (modalType: ModalType): Promise<void> => {
    const biParams = {origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT, mode: 'editor', isMerchant: true};

    switch (modalType) {
      case ModalType.SetShipping: {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showShippingPopupSf({
          type: 'merchant pop-up',
          ...biParams,
        });
        return this.modalManger.openSetShippingMethod();
      }

      case ModalType.SetPayment: {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showMerchantPaymentPopupSf(biParams);
        return this.modalManger.openSetPaymentMethod();
      }
      case ModalType.UpgradeToPremium: {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.showMerchantUpgradePopupSf(biParams);
        const response = await this.modalManger.openUpgradeToPremium();
        if (!(response && response.proceed)) {
          return;
        }
        break;
      }
      case ModalType.NotInLiveSite: {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.viewCheckoutInLiveSitePopupSf(biParams);
        return this.modalManger.openNotInLiveSite();
      }
      case ModalType.NoOnlinePayments: {
        // tslint:disable no-floating-promises
        this.siteStore.biLogger.notAcceptPaymentsVisitorPopupSf({origin: Origin.PRODUCT_PAGEֹֹ_CHECKOUT});
        this.nextProps({modalState: ModalState.OPEN});
        return this.modalManger.openNoOnlinePayments().then(() => {
          this.nextProps({modalState: ModalState.CLOSE});
        });
      }
    }
  };

  public checkIsAllowedToCheckout = async (product: IProductDTO): Promise<ICanCheckoutResponse> => {
    const storeInfo = await this.storeInfoService.fetchStoreInfo().catch(e => {
      throw e;
    });

    const canStoreShip = product.productType === 'digital' || storeInfo.canStoreShip;

    if (this.siteStore.isPreviewMode() || this.siteStore.isEditorMode()) {
      if (!canStoreShip) {
        return {modalType: ModalType.SetShipping, canCheckout: false};
      } else if (!storeInfo.hasCreatedPaymentMethods) {
        return {modalType: ModalType.SetPayment, canCheckout: false};
      } else if (!storeInfo.isPremium) {
        return {modalType: ModalType.UpgradeToPremium, canCheckout: false};
      } else {
        return {modalType: ModalType.NotInLiveSite, canCheckout: false};
      }
    } else if (!storeInfo.isPremium || !storeInfo.hasCreatedPaymentMethods || !canStoreShip) {
      this.siteStore.experiments.enabled(SPECS.ENABLE_LOST_BUSINESS_EMAIL) && this.sendLostBusinessEmail(storeInfo);
      return {modalType: ModalType.NoOnlinePayments, canCheckout: false};
    }

    return {modalType: undefined, canCheckout: true};
  };

  public navigateToCheckout = async (checkoutInfo: CheckoutInfo): Promise<void> => {
    if (await this.isEligibleForCheckoutInViewer()) {
      const queryParams = {
        a11y: checkoutInfo.a11y,
        cartId: checkoutInfo.cartId,
        storeUrl: checkoutInfo.siteBaseUrl, //this param is not used in client or server but needed by cashier! dont remove it!
        isFastFlow: checkoutInfo.isFastFlow,
        isPickupFlow: checkoutInfo.isPickupFlow,
        cashierPaymentId: '',
      };
      await this.siteStore.navigate(
        {
          sectionId: PageMap.CHECKOUT,
          queryParams,
        },
        true,
        true
      );
    } else {
      await this.navigateToCheckoutOutViewer(checkoutInfo);
    }
  };
}

import {ErrorNames, productPageFedopsEvent} from '../constants';
import {BiButtonActionType} from '@wix/wixstores-client-core/dist/es/src/constants';
import {formatCustomTextFields} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {VolatileCartService} from './VolatileCartService';
import {CheckoutService} from './CheckoutService';
import {ICanCheckoutResponse, IProductDTO, IPropsInjectedByViewerScript, UserInput} from '../types/app-types';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import _ from 'lodash';

export enum CheckoutType {
  BuyNow,
  Subscribe,
}
export class DirectPurchaseService {
  private readonly checkoutService: CheckoutService;
  private readonly volatileCartService: VolatileCartService;

  constructor(
    private readonly siteStore: SiteStore,
    private readonly reportError: (e) => any,
    private readonly reportButtonAction: (buttonType: BiButtonActionType) => void,
    private readonly nextProps: (additionalProps: Partial<IPropsInjectedByViewerScript>) => void,
    private readonly fedopsLogger: any,
    private readonly trackEvent: () => void,
    private readonly onSuccess: Function
  ) {
    this.volatileCartService = new VolatileCartService(this.siteStore.httpClient);
    this.checkoutService = new CheckoutService(this.siteStore, this.siteStore.httpClient, this.nextProps);
  }

  private readonly getPathFromUrl = (url: string) => {
    return url.split(/[?#]/)[0];
  };

  public handleError = (): void => {
    this.nextProps({errors: {errorName: ErrorNames.StoreInfoError}});
  };

  public handleBuyNow = async (
    accessibilityEnabled: boolean,
    product: IProductDTO,
    userInputs: UserInput
  ): Promise<void> => {
    const canCheckoutResponse: ICanCheckoutResponse = await this.checkoutService
      .checkIsAllowedToCheckout(product)
      .catch(e => {
        this.reportError(e);
        return {} as ICanCheckoutResponse;
      });

    if (_.isEmpty(canCheckoutResponse)) {
      this.handleError();
      return;
    }

    this.reportButtonAction(BiButtonActionType.BuyNow);
    this.trackEvent();

    if (!canCheckoutResponse.canCheckout) {
      // tslint:disable no-floating-promises
      this.fedopsLogger.interactionEnded(productPageFedopsEvent.BuyNow);
      // tslint:disable no-floating-promises
      this.checkoutService.openModalByType(canCheckoutResponse.modalType);
    } else {
      return this.handleCheckoutStage(accessibilityEnabled, product, userInputs, CheckoutType.BuyNow);
    }
  };

  public handleSubscribe = async (
    accessibilityEnabled: boolean,
    product: IProductDTO,
    userInputs: UserInput
  ): Promise<void> => {
    const canCheckoutResponse: ICanCheckoutResponse = await this.checkoutService
      .checkIsAllowedToCheckout(product)
      .catch(e => {
        this.reportError(e);
        return {} as ICanCheckoutResponse;
      });

    if (_.isEmpty(canCheckoutResponse)) {
      this.handleError();
      return;
    }

    this.reportButtonAction(BiButtonActionType.Subscribe);
    this.trackEvent();

    if (!canCheckoutResponse.canCheckout) {
      // tslint:disable no-floating-promises
      this.fedopsLogger.interactionEnded(productPageFedopsEvent.Subscribe);
      // tslint:disable no-floating-promises
      this.checkoutService.openModalByType(canCheckoutResponse.modalType);
    } else {
      return this.handleCheckoutStage(accessibilityEnabled, product, userInputs, CheckoutType.Subscribe);
    }
  };

  public handleCheckoutStage = async (
    accessibilityEnabled: boolean,
    product: IProductDTO,
    userInputs: UserInput,
    checkoutType: CheckoutType
  ) => {
    let cartId, thankYouPageSectionUrl, cartPageSectionUrl, checkoutUrl;
    [cartId, thankYouPageSectionUrl, cartPageSectionUrl, checkoutUrl] = await Promise.all([
      this.volatileCartService.getStandaloneCartId(
        product.id,
        userInputs.selection.map(selected => selected.id),
        userInputs.quantity[0],
        formatCustomTextFields(product, userInputs).map(customTextField => {
          return {title: customTextField.customText.title, value: customTextField.answer};
        }),
        checkoutType === CheckoutType.Subscribe ? userInputs.subscriptionPlan[0].id : undefined
      ),
      this.siteStore.getSectionUrl(this.siteStore.pageMap.thankyou),
      this.siteStore.getSectionUrl(this.siteStore.pageMap.cart),
      this.siteStore.getSectionUrl(this.siteStore.pageMap.checkout),
    ]).catch(this.reportError);

    const checkoutRelativeUrl = checkoutUrl.relativeUrl;
    const thankYouPageUrl = this.getPathFromUrl(thankYouPageSectionUrl.url);
    const cartPageUrl = this.getPathFromUrl(cartPageSectionUrl.url);
    const deviceType = this.siteStore.isDesktop() ? 'desktop' : 'mobile';

    this.fedopsLogger.interactionEnded(
      checkoutType === CheckoutType.Subscribe ? productPageFedopsEvent.Subscribe : productPageFedopsEvent.BuyNow
    );

    await this.checkoutService.navigateToCheckout({
      cartId,
      isFastFlow: false,
      isPickupFlow: false,
      checkoutRelativeUrl,
      siteBaseUrl: this.siteStore.location.baseUrl,
      thankYouPageUrl,
      cartUrl: cartPageUrl,
      paymentMethodName: undefined,
      locale: this.siteStore.locale,
      deviceType,
      a11y: accessibilityEnabled,
    });

    return this.onSuccess();
  };
}
